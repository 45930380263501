import React, { useState} from 'react';
import Cardlist from "../cardlist/cardlist.pages";
import './findtruck.styles.scss';
import mapicon from '../../assets/img/mapicon.png';
import DatePicker from "react-datepicker";
import Filter from '../../components/filter/filter.component';
import "react-datepicker/dist/react-datepicker.css";
import "../../components/datepick/datepick.styles.scss";



const list=  [{
    key: 1,
    title: "amine",
    type: "frigo",
    time: "friday",
    location: "casablanca",
    price: "275 MAD"
    },
    {
        key: 2,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 3,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 4,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 5,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 6,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 7,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 8,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 9,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 10,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
    {
        key: 11,
        title: "atlagh",
        type: "sec",
        time: "tuesday",
        location: "rabat",
        price: "200 MAD"
    },
]

const cities= [{name: "Casablanca", country:"Morocco", id: 1}, {name: "Rabat", country:"Morocco", id: 2}, {name: "Tangier", country:"Morocco", id: 3}, {name: "Marrakech", country:"Morocco", id: 4}, {name: "Fes", country:"Morocco", id: 5}, {name: "Tetouane", country:"Morocco", id: 6}, {name: "Agadir", country:"Morocco", id: 7}]


const Findtruck = () => {

    const [isFocusedFrom, setIsFocusedFrom] = useState(false);
    const [isFocusedTo, setIsFocusedTo] = useState(false);
    const [FromPlaceHolder, setFromPlaceHolder] = useState("");
    const [ToPlaceHolder, setToPlaceHolder] = useState("");
    const [filteredCities, setFilteredCities] = useState(cities);
    const [startDate, setStartDate] = useState(new Date());



    const handleOnFocusFrom = () => { 
        setTimeout(() => {
            setIsFocusedFrom(true); 
          }, 130); 
    }; 
    const handleBlurFrom = () => { 
        setTimeout(() => {
            setIsFocusedFrom(false); 
          }, 130); 
    }; 

    const handleOnFocusTo = () => { 
        setTimeout(() => {
            setIsFocusedTo(true); 
          }, 130); 
    }; 
    const handleBlurTo = () => { 

        setTimeout(() => {
            setIsFocusedTo(false); 
          }, 130);
         
    };
    const onClickChoiceTo = (data) => { 
        
        setToPlaceHolder(data); 
    };
    const onClickChoiceFrom = (data) => { 
        
        setFromPlaceHolder(data); 
    };

    const handleChangeFrom = (event) => {

        setFromPlaceHolder(event.target.value);
        const filtered = cities.filter(city =>city.name.toLowerCase().includes(event.target.value.toLowerCase()) );
        setFilteredCities(filtered);
     
      };
      const handleChangeTo = (event) => {

        setToPlaceHolder(event.target.value);
        const filtered = cities.filter(city =>city.name.toLowerCase().includes(event.target.value.toLowerCase()) );
        setFilteredCities(filtered);
     
      };
    


    return (
        <>
            <div className='head-margin'>

            </div>
            
            <div className="search">
                <div className="from">
                    <input
                            className="inputStyle"
                            type="text"
                            placeholder="From"
                            value={FromPlaceHolder}
                            onChange={handleChangeFrom}
                            onFocus={handleOnFocusFrom}
                            onBlur={handleBlurFrom}
                            
                    />
                    <div className={`test2 ${(isFocusedFrom ? "" : "hide")}`}>
                        {filteredCities.map((city) => (  
                            <div className="inputChoices" key={city.id} onClick={() => onClickChoiceFrom(city.name)}>
                                
                                <div style={{display: "flex"}}>
                                    
                                    <img src={mapicon} alt="mapicon" className='mapicon'/>
                                    
                                    <div>
                                        <div style={{fontWeight: "bold"}}>{city.name}</div> 
                                        <div >{city.country}</div> 
                                    </div>

                                </div>
                            
                            
                            
                            </div>
                        )) }
                    </div>
                </div>

                <div className="to">
                    <input
                            className='inputStyle' 
                            type="text"
                            placeholder="To"
                            value={ToPlaceHolder}
                            onChange={handleChangeTo}
                            onFocus={handleOnFocusTo}
                            onBlur={handleBlurTo}
                    />
                    <div className={`test2 ${(isFocusedTo ? "" : "hide")}`}>
                        {filteredCities.map((city) => (  
                            <div className="inputChoices" key={city.id} onClick={() => onClickChoiceTo(city.name)}>
                                
                                <div style={{display: "flex"}}>
                                <img src={mapicon} alt="mapicon" className='mapicon'/>
                                

                                <div>
                                    <div style={{fontWeight: "bold"}}>{city.name}</div> 
                                    <div >{city.country}</div> 
                                </div>

                            </div>
                                
                                
                                </div>
                        )) }
                    </div>
                </div>

                <div className="date"  >
                    <DatePicker 
                    style={{transform:"translate(1.2)"}}
                        calendarClassName="calendar-class"
                        selected={startDate} 
                        onChange={(date) => setStartDate(date)}
                    /> 
        
                </div> 
                <input type="submit" className='search-button button-43' />
                
            </div>



            <div className="filter-truck">
                <div className="filter">
                    <Filter />
                </div>
                <div className="trucks"><Cardlist  arr={list}/></div>
            </div>
        </>
    )
}

export default Findtruck;